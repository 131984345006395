export function getNumberedEnv(host: string): string | null {
  const numberedEnv = host.match(/^dev\d{1,2}/);
  return numberedEnv?.length ? numberedEnv[0] : null;
}

export function isHostNumberedEnv(host: string): boolean {
  return /^dev\d{1,2}/.test(host);
}

export const appVersion: string = "3.0.0-feature-ST-3008-Use-Correct-Tokens-Package.2";
export const appTimestamp: string = "2025-04-02T10:55:14.000Z";
export const commitId: string = "f2d5489a5c770c9e7688f828b14107cb6635d346";
export const commitUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/AdminPanel/commit/f2d5489a5c770c9e7688f828b14107cb6635d346?refName=refs%2Fheads%2Fmaster";
export const pullRequestId: string = "32307";
export const pullRequestUrl: string = "https://dev.azure.com/faro-connect/Stellar/_git/AdminPanel/pullrequest/32307";
export const jiraTicketId: string = "ST-3008";
export const jiraTicketUrl: string = "https://faro01.atlassian.net/browse/ST-3008";

export const sentryDsn =
  "https://a5f0e653e508490282b7d14d19443b9f@o381590.ingest.sentry.io/5580037";

export const isNumberedEnv = isHostNumberedEnv(window.location.host);
export const numberedEnv = isNumberedEnv
  ? getNumberedEnv(window.location.host)
  : null;
